@import "@/src/styles/common.scss";
.product-package-container {
    background: #fff;
    margin-top: 5px;
    padding-bottom: 0.4rem;
    width: 100%;
    overflow: hidden;
    .product-package-list {
        width: 100%;
        padding-left: 10px;
        position: relative;
        min-height: 5.25rem;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            width: 0 !important;
        }
        .product-package-href {
            display: flex;
            width: fit-content;
            .loading-container {
                width: 2rem;
                height: 5.25rem;
            }
        }
    }
    .product-package-title {
        @include use-font-title();
        padding: 0.4rem 0 0.3rem 0.2rem;
    }
}
