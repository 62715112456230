@import "@/src/styles/common.scss";
.product-package-item {
    padding: 0.2rem;
    margin-right: 0.2rem;
    border: 0.02rem solid $main;
    .product-package-item-img {
        margin-bottom: 0.2rem;
        height: 2rem;
        position: relative;
        cursor: pointer;
        text-align: center;
        img {
            height: 2rem;
            width: auto;
            object-fit: contain;
            margin: 0 auto;
        }
    }
    .title-container {
        justify-content: space-between;
        .icon-faq {
            @include use-icon-normal();
        }
        .product-package-item-title {
            @include use-font-normal();
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
        }
    }
    .product-package-item-wrapper {
        cursor: pointer;
        position: relative;
        width: 100%;
        .product-package-item-text {
            @include use-font-small($text_3);
            min-height: 0.62rem;
            margin: 0.2rem 0;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .product-package-item-price {
            @include use-font-small();
            margin-bottom: 0.2rem;
            s {
                margin-left: 0.02rem;
                @include use-font-small($text_3);
            }
        }
    }
}

// dialog
.dialog-content {
    .package-detail-modal {
        padding: 0;
        text-align: left;
        .label-item {
            margin-top: 0.1rem;
        }
        .title {
            @include use-font-normal();
            margin-bottom: 0.2rem;
        }
        .text {
            @include use-font-normal($placeholderColor);
            margin-bottom: 0.3rem;
            text-align: center;
        }
        .price-text {
            @include use-font-small(#f8ac59);
            margin-top: 0.4rem;
        }
    }
}
