@import "@/src/styles/common.scss";
.banner-container {
    width: 100%;
    overflow: hidden;
}
.banner-box {
    width: 100%;
    min-height: 45.5vw;
    position: relative;
    .banner-top {
        width: 100%;
        max-width: 100rem;
    }

    .banner-h1 {
        position: absolute;
        top: 0.1rem;
        left: 0.5rem;
        z-index: 1;
        .banner-h1-content {
            font-size: 0.48rem;
            max-width: 3rem;
            color: #fff;
        }
        .spec-sub-sub-title {
            display: block;
            font-size: 0.3rem;
            color: #fff;
            max-width: 4rem;
        }
    }
}

.terms-condition {
    position: relative;
    color: #aaa;
    margin-top: 0.1rem;
    margin-left: 0.1rem;
    font-size: 0.3rem;
}

.condition-modal {
    text-align: left;
    padding-top: 0.2rem;
    width: 100%;
    overflow: scroll;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &-content {
        background: white;
        font-size: 0.28rem;
        border-radius: 0.08rem;
        width: 6.5rem;
        padding: 0.1rem 0.29rem 0.29rem;
        color: #888;
        line-height: 1.5;
        letter-spacing: 0.3pt;
    }
}

.contion-onClose {
    display: flex;
    justify-content: flex-end;
    padding: 0.1rem 0;
}

.specsub-bottom-ad-desc {
    font-size: 0.28rem;
    line-height: 0.4rem;
}

.p-l-r-15 {
    padding: 0 0.3rem;
}

.specsub-bottom-ad-img {
    margin: 0.2rem 0;
}
.about-page {
    font-size: 0.4rem;
    text-align: center;
    margin-bottom: 0.2rem;
    padding: 0 0.3rem;
}

.bottom-help-list-item {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 0.2rem 0px;
    color: #1d2023;
    padding: 0 0.2rem;
    font-size: 0.32rem;
}
