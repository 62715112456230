@import "@/src/styles/common.scss";
.filter-select-container {
    position: relative;
    height: 100%;
    .title {
        height: 100%;
        display: flex;
        align-items: center;
        transition:
            background-color 2s,
            color 0.3s;

        .title-p {
            @include use-font-title();
            white-space: nowrap;
            overflow: hidden;
            max-width: 85%;
            text-overflow: ellipsis;
        }
        .panel-container {
            transition: transform 0.3s ease 0s;
            .slider-panel-icon {
                @include use-icon-small();
                margin-right: 0.12rem;
                margin-left: 0;
                transition: transform 0.3s;
            }
            &.panel-rotate {
                margin-left: 0.12rem;
                margin-right: 0;
            }
        }
    }
}

.select_wrap {
    overflow: hidden;
}

@keyframes slideDown {
    from {
        max-height: 0;
        opacity: 0;
    }

    to {
        max-height: 100%;
        opacity: 1;
    }
}

.select-container {
    width: 100vw;
    left: -0.2rem;
    position: absolute;
    background: #fff;
    z-index: 1201;
    transition: max-height 1s ease-in-out;
    box-shadow: 0px 20px 20px 0px rgb(0 0 0 / 10%);
    &.slideDown {
        animation: slideDown 0.1s;
    }
    .item {
        width: 100%;
        height: 1rem;
        border-top: 0.02rem solid $gray_4;
        padding-left: 0.3rem;
        font-size: 0.28rem;
        font-family: var(--font-montserrat);
        color: rgba(69, 69, 69, 1);
        line-height: 1rem;
        background: #fff;
        &.active {
            color: $main;
        }
    }
}

.selectimg {
    font-size: 0.24rem;
    font-family: var(--font-montserrat-bold);
    margin-left: 0.12rem;
    transition: transform 0.3s;
}
