@import "@/src/styles/common.scss";
.filter-nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0.2rem;
    padding-top: 0.4rem;
}

.filter-content-item {
    position: relative;
}

.filter-content-color {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: #888;
    font-size: 0.28rem;

    .filter-color {
        width: 50%;
        display: flex;
        align-items: center;
        p {
            font-size: 0.32rem;
            font-family: var(--font-montserrat);
            color: rgba(45, 45, 45, 1);
        }
    }
    .filter-color:nth-of-type(n + 3) {
        margin-top: 0.4rem;
    }

    .color-item {
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.02rem solid #999999;
        margin-right: 0.2rem;
        flex-shrink: 0;

        .color-item-back {
            width: 0.4rem;
            height: 0.4rem;
            background: rgba(0, 0, 0, 1);
            border-radius: 50%;
        }
    }

    .color-item-active {
        border: 0.04rem solid $main;
    }
}

.filter-content-Radio {
    margin-bottom: 0.4rem;
}

.filter-content-Radio-title {
    @include use-font-title();
    margin-bottom: 0.4rem;
    text-align: left;
}

.filter-modal-content {
    flex: 1;
    background: #fff;
    height: 100%;
}

.filter-nav-bar-container {
    .header-bottom {
        height: 1rem !important;
        font-size: 0.4rem !important;
    }
    .filter-nav-bar-children-container {
        overflow: hidden !important;
        padding: 0.2rem 0 0 0 !important;
        .tabs-container {
            border: none;
            padding: 0;
            height: auto;
            padding-bottom: 0.4rem;
            border-bottom: 0.02rem solid $gray_4;
            .flexContainer {
                flex-wrap: wrap;
            }
            .indicator {
                opacity: 0;
            }
            .tab-item {
                border-radius: 0px;
                cursor: pointer;
                user-select: none;
                white-space: normal;
                margin: 0;
                padding: 0.12rem 0.32rem;
                @include use-font-normal($text_2);
                font-family: var(--font-montserrat-medium);
                width: auto;
                border: 0.02rem solid #848a90;
                &.filter-nav-label {
                    border-radius: 0;
                }
                &.selected-item {
                    color: #fff;
                    background-color: $main;
                    border-color: $main;
                }
            }
            .tab-item {
                margin-left: 0.4rem;
                margin-top: 0.26rem;
            }
        }
        .tabPanelClassName {
            max-height: 100%;
            overflow: auto;
            padding: 0.4rem;
            scrollbar-width: none;
        }
        .tabPanelClassName::-webkit-scrollbar {
            width: 0; /* 隐藏 y 轴滚动条 */
        }
        // body
        .filter-box {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }
    }
}

.hidden {
    display: none !important;
}
