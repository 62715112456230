@import "@/src/styles/common.scss";
.count-down-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.realtime {
    padding-right: 0.05rem;
    .count-down-font {
        font-size: 0.26rem;
        padding-left: 0.5em;
    }
}

.pc-count-down-font {
    font-size: 14px;
}

.day {
    padding-left: 0.5em;
}

.count-down-cms-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .count-down-container.with-children {
        margin-left: 0.3rem;
    }
    .timeClass {
        font-size: 0.24rem;
        padding: 0.12rem 0.06rem;
        border-radius: 0.08rem;
        margin: 0;
        display: block;
        min-width: 0.4rem;
        text-align: center;
    }
    .timeDoc {
        margin: 0.06rem;
        font-size: 0.28rem;
    }
}

.timeClass {
  font-family:var(--font-montserrat-medium);
  font-size: 14px;
  color: var(--color);
  line-height: 1;
  text-align: left;
  font-style: normal;
  text-transform: none;
  background: var(--bg-color);
  border-radius: 4px;
  padding: 6px 6px;
  min-width: 18px;
  text-align: center;
}
.timeDoc {
  color: var(--bg-color);
  margin: 0 6px;
  font-size: 14px;
}
