@import "@/src/styles/common.scss";
.z-index-zero {
    z-index: 0 !important;
}

.skeleton-item-container {
    display: flex;
    flex-wrap: wrap;
    &.single {
        flex-direction: column;
    }
}
