@import "@/src/styles/common.scss";
.product-slider-container {
    padding: 0.2rem 0 0.4rem;
    background: #fff;
    position: relative;
    // carousel
    .carousel-container {
        position: relative;
        min-height: 4rem;
        margin-bottom: 0.1rem;
        .ai-btn {
            position: absolute;
            left: 0.1rem;
            cursor: pointer;
            bottom: 0px;
            .show-ai-wrapper {
                justify-content: flex-start;
                align-items: flex-start;
                .tip-content {
                    max-width: 90%;
                    color: #fff;
                    font-size: 0.24rem;
                    margin-left: 0.1rem;
                    overflow: hidden;
                    & > div {
                        background: rgba(0, 0, 0, 0.4);
                        transform: translateX(-101%);
                        padding: 0.06rem 0.16rem;
                        transition: all 0.3s;
                    }
                    &.tip-show > div {
                        transform: translateX(0);
                    }
                }
            }
        }
    }
    .video-container {
        justify-content: space-between;
        padding: 0 0.2rem;
        .video-button {
            @include use-font-small($text, !important);
            border-radius: 0.2rem !important;
            padding: 0 0.1rem !important;
            height: auto !important;
        }
        .current-index {
            padding: 0 0.2rem;
            border-radius: 0.18rem;
            background: rgba(0, 0, 0, 0.4);
            @include use-font-small(#fff);
        }
        .guide {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p {
                @include use-font-normal();
                text-align: center;
                color: #fff;
                margin-top: 0.2rem;
            }
        }
    }
}
.share-button.share-icon {
    @include use-icon-normal(null, $text);
    position: absolute;
    top: 0.4rem;
    right: 0.3rem;
}
