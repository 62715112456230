@import "@/src/styles/common.scss";
.home-container {
  background-color: #f9f9f9;

  .banner-container {
    .banner-img-box-container {
      min-height: 6.8rem;
    }

    .banner-img-box {
      height: 7rem;

      .image-content {
        display: block;
      }
    }

    .flex-line {
      justify-content: space-between;
      margin-top: 0.3rem;

      .sub-image-container {
        min-height: 3rem;
        width: 3.6rem;
      }
    }
  }

  .h5-info-bar-container {
    .communication-container {
      flex-wrap: wrap;
      padding: 0.05rem 0;
      justify-content: center;
      align-items: flex-start;

      .communication-item {
        padding: 0.08rem 0.2rem;
        box-sizing: border-box;
        justify-content: flex-start;

        .image-content {
          width: 20px;
          margin-right: 0.05rem;
        }

        .text-content {
          font-size: 0.24rem !important;
        }
      }
    }
  }

  .propaganda-container {
    .PropagandaCms-title {
      padding: 0.8rem 0.6rem 0.4rem;
      font-size: 18px;
      text-align: center;
      font-family: var(--font-montserrat-bold);
    }

    .PropagandaCms-text {
      font-size: 14px;
      line-height: 1.4;
      text-align: center;
      padding: 0 15px;
      padding-bottom: 20px;
    }
  }

  // product-panel
  .home-product-panel {
    background: #fff;

    .tab-bar-container {
      height: 1.16rem;

      .tab-root {
        padding-top: 0.34rem;
        @include use-font-title();
        margin: 0 !important;
      }

      .tab-selected {
        color: $main;
      }

      .tab-indicator {
        background-color: $main;
      }
    }

    .tab-panel-content {
      margin: 0 0.2rem;

      .viewlist-container {
        .double-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0;

          // margin-top: 0.36rem;
          // home-product-item
          .home-product-item {
            width: calc(50% - 0.15rem);
            margin-top: 0.36rem;
            padding: 0.1rem 0.2rem 0.4rem;
            background: #fff;
            box-shadow: 0px 6px 12px 2px rgba(0, 0, 0, 0.05);

            .home-product-icons-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
              /* 这里必须要给高度，因为是双列，可能相邻的两个一个有 一个没有 高度就不一致了 */
              height: 0.32rem;

              .tag-container {
                box-sizing: content-box;
                padding: 0.128rem 0 0.18rem 0;
              }

              .favorite-icon-btn {
                color: $main !important;
                @include use-icon-small();
              }
            }

            .home-product-item-container {
              .home-product-item-img {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;

                img {
                  width: 3.16rem;
                  height: 1.58rem;
                }
              }

              .color-select-wrapper,
              .line {
                display: flex;
                align-items: center;
              }

              .color-select-wrapper {
                justify-content: center;
              }

              .line {
                justify-content: space-between;
              }

              .color-select-wrapper,
              .home-product-price {
                margin-top: 0.34rem;
              }

              .home-product-price {
                .price {
                  margin-bottom: 0;
                  flex: 1;
                  position: relative;
                  @include use-font-normal();
                  font-family: var(--font-montserrat-bold);

                  s {
                    font-size: 0.24rem;
                    color: $text_3;
                    margin-right: 0.1rem;
                  }

                  .top-s {
                    position: absolute;
                    top: -0.3rem;
                  }
                }

                .home-try-on {
                  font-size: 0.25rem;
                  color: $main;
                  font-family: var(--font-montserrat);
                }
              }

              .glass-hidden-wrapper {
                position: static;

                .glass-hidden-container {
                  left: -0.2rem;
                }
              }
            }
          }
        }
      }

      .view-all {
        padding: 0.4rem 0.2rem 0.3rem;
        display: flex;
        justify-content: center;
        font-size: 0.3rem;
        font-family: var(--font-montserrat-bold);
      }
    }
  }

  // combination-container
  .combination-container {
    margin-top: 0.2rem;
    padding: 0.1rem 0.2rem 0.2rem;
    background: #fff;

    .h5-combination-container {
      &>div {
        width: 100%;
        position: relative;
        text-align: center;
      }

      .title {
        font-size: 18px !important;
        font-family: var(--font-montserrat-bold);
        color: #fff;
      }

      .text {
        font-size: 0.24rem !important;
        margin-top: 0.1rem;
        color: #fff;
      }

      .shop-btn {
        border: 0.02rem solid var(--btn-color);
        line-height: 0.72rem;
        font-size: 0.28rem;
        padding: 0 0.1rem;
        color: var(--btn-color);
        text-align: center;
        font-family: var(--font-montserrat-medium);
        cursor: pointer;
        width: max-content;
        margin: 0.4rem auto 0;

        &:hover {
          background-color: var(--btn-color);
          color: #fff;
        }

        &.primary-button {
          color: #fff;

          &:hover {
            border-color: var(--btn-color);
            background-color: transparent !important;
            color: var(--btn-color);
          }
        }

        // 只摆放一个item时间距可正常设置
        &.padding-4 {
          padding: 0 0.4rem;
        }
      }

      .combination-text-container {
        text-align: center;
        padding: 0.4rem 0.2rem;
        width: 100%;

        &.fixed-text {
          position: absolute;
          bottom: 0;

          .title,
          .text {
            color: #fff;

          }

          &.mask {
            background: linear-gradient(180deg, rgba(242, 242, 242, 0) 0%, #000000 100%);
          }
        }

        .title {
          @include use-font-title($text);
        }

        .text {
          @include use-font-small($text);
          margin-top: 0.2rem;
        }
      }

      .shop-btn-mb-0 {
        margin-bottom: 0;
      }

      .shop-btn-mb-1 {
        border-radius: 0;
      }

      .shop-btn-mb-2 {
        border-radius: 0.08rem;
      }

      .shop-btn-mb-3 {
        border-radius: 0.3rem;
      }

      .shop-btn-mb-4 {
        margin-bottom: 0.4rem;
      }

      &:not(.type-1-class) {
        .image-container {
          margin-bottom: 0.4rem;
        }

        .title-container {
          width: 100%;
        }
      }

      &.type-1-class {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &>div {
          width: 49%;
        }

        .title {
          margin-top: 0.3rem;
          font-size: 18px !important;
        }

        .ad_list_margin {
          margin-bottom: 0.2rem;
        }

        .title-container {
          text-align: center;
          padding: 0 10px;
        }
      }
    }
  }

  // product-recommend-container
  .product-recommend-container {
    padding: 0.8rem 0 0.4rem;

    .countdown-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.2rem;
    }

    .title {
      @include use-font-title($text);
    }

    .only-title {
      width: 100%;
      text-align: center;
    }

    .product-list-wrapper {
      margin: 0 0 0.6rem 0;
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
    }

    .shop-more {
      width: 70%;
    }

    .shop-more-btn {
      width: 100%;
      line-height: 0.82rem;
      border-radius: 0.08rem;
      background: #121a24;
      color: #fff;
      text-align: center;
      font-size: 0.36rem;
      font-family: var(--font-montserrat-medium);
      text-overflow: ellipsis;
      word-break: break-word;
      white-space: nowrap;
    }

    .item-wrapper {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0.06rem 0.12rem 0.02rem rgba(126, 126, 126, 0.16);
    }

    .areaCart {
      width: 100%;
      height: 100%;
      background: #fff;
      padding: 0.8rem 0.2rem 0.6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      height: 100%;
      box-shadow: 0px 0.06rem 0.12rem 0.02rem rgba(126, 126, 126, 0.16);
      border-radius: 0;
    }

    .navigation-wrapper {
      position: inherit;
    }

    .styleColl {
      font-size: 0.28rem;
      font-family: var(--font-montserrat-light);
      color: #121a24;
      margin-top: 0.2rem;
    }

    .price-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0.16rem;
    }

    .priceColl,
    .price {
      font-size: 0.28rem;
      font-family: var(--font-montserrat-medium);
    }

    .priceColl {
      color: #aaaaaa;
      text-decoration: line-through;
      margin-right: 0.2rem;
    }

    .price {
      color: #121a24;
    }
  }

  // comment-area-container
  .comment-area-container {
    background-color: #fff;
    margin-top: 0.2rem;

    .comment-area-title {
      @include use-font-title();
      text-align: center;
      padding: 0.8rem 0.6rem 0;
    }

    .comment-area-describe {
      @include use-font-small();
      text-align: center;
      margin-top: 0.4rem;
      padding: 0 0.3rem 0.4rem;
    }

    .area-cart-container {
      width: 100%;
      height: 100%;
      background: #f6f6f6;
      border-radius: 8px 8px 8px 8px;
    }

    .area-cart-warrper {
      padding: 0.4rem 0.4rem 0.6rem;
    }

    .area-cart-content {
      display: flex;
      flex-direction: column;
    }

    .area-cart-user-wrapper {
      height: 100%;
    }

    .area-cart-user {
      display: flex;
      align-items: center;
    }

    .area-avatar-username {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      margin-left: 0.2rem;
    }

    .area-auths-tit {
      @include use-font-title();
      color: #121a24;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .area-auths-des {
      @include use-font-small();
      color: #26abfe;
      margin-top: 0.12rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .area-tit-narrate {
      display: -webkit-box;
      /* 旧版本 Safari 和 Chrome 需要添加此属性 */
      -webkit-box-orient: vertical;
      /* 旧版本 Safari 和 Chrome 需要添加此属性 */
      overflow: hidden;
      /* 隐藏超出容器的文本 */
      text-overflow: ellipsis;
      /* 超出时显示省略号 */
      -webkit-line-clamp: 7;
      /* 指定最大行数 */
      line-clamp: 7;
      /* 指定最大行数 */
      font-size: 0.28rem;
      font-family: Roboto-Regular;
      color: #121a24;
      line-height: 1.5;
    }
  }

  // user-rotation-container
  .user-rotation-container {
    background-color: #fff;

    .user-rotation-title {
      @include use-font-title();
      text-align: center;
      padding: 0.8rem 0.6rem 0;
    }

    .user-rotation-describe {
      @include use-font-small();
      text-align: center;
      margin-top: 0.4rem;
      padding: 0 0.3rem 0.4rem;
    }

    .user-rotation-item {
      height: 310px;
    }

    .big-box {
      position: relative;
      cursor: pointer;
    }

    .word-box {
      position: absolute;
      width: 100%;
      height: 25%;
      bottom: 0;
      background: linear-gradient(180deg,
          rgba(242, 242, 242, 0) 0%,
          #000000 100%);
      opacity: 0.63;
    }

    .word-box-tie {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: center;
      color: white;
      background: linear-gradient(180deg,
          rgba(242, 242, 242, 0) 0%,
          rgba(0, 0, 0, 0.63) 100%);
    }

    .word-box-title {
      font-size: 15px;
      font-weight: 600;
      width: 200px;
      margin-bottom: 10px;
      white-space: nowrap;
      /* 防止文本换行 */
      overflow: hidden;
      /* 隐藏超出容器的文本 */
      text-overflow: ellipsis;
      /* 超出时显示省略号 */
      text-align: center;
    }

    .word-box-tdes {
      font-size: 12px;
      font-weight: 300;
      width: 100%;
      display: -webkit-box;
      /* 旧版本 Safari 和 Chrome 需要添加此属性 */
      -webkit-box-orient: vertical;
      /* 旧版本 Safari 和 Chrome 需要添加此属性 */
      overflow: hidden;
      /* 隐藏超出容器的文本 */
      text-overflow: ellipsis;
      /* 超出时显示省略号 */
      -webkit-line-clamp: 2;
      /* 指定最大行数 */
      line-clamp: 2;
      /* 指定最大行数 */
      padding: 0 10px;
      text-align: center;
    }

    .word-play {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: max-content;
      height: max-content;
      line-height: 1;
      opacity: 1;
    }

    .video-wrapper {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
}

.skeleton-front-page-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.skeleton-front-page {
  width: calc(50% - 0.1rem);
  margin-top: 0.36rem;
  padding: 0.13rem 0.18rem 0.25rem;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border-radius: 0.08rem;

  .empty-tag {
    box-sizing: content-box;
    padding: 0.128rem 0px 0.18rem;
    height: 0.32rem;
    visibility: hidden;
  }

  .color-container,
  .text-container {
    display: flex;
    justify-content: space-between;

    &>div {
      margin: 0;
    }
  }

  .color-container {
    margin-top: 0.44rem;
  }

  .text-container {
    margin-top: 0.32rem;
  }
}

.tab-wrapper-simulation {
  background-color: #fff;
  border-bottom: 0.04rem solid rgba(0, 0, 0, 0.11);
  padding: 0.34rem 0;
  margin: 0 auto;
  text-align: center;
  line-height: 1;
  display: flex;

  .tab-simulation {
    font-size: 0.48rem;
    margin: 0 auto;
    line-height: 1;
  }
}

.video-right-close {
  top: .3rem;
  position: relative;
  transform: translateY(100%);
  border-radius: 50%;
  padding: 0.1rem;
  box-sizing: content-box;
  border: 1px solid #e4e4e4;
  &::before {
    left: 0.1rem !important;
    font-size: 0.46rem;
    vertical-align: middle;
    color: rgb(255, 255, 255);
  }
}