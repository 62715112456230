@import "@/src/styles/common.scss";
.addon-modal-container {
    .addon-modal-header {
        text-align: center;
        font-size: 0.28rem !important;
    }
    .carousel-container {
        width: 100%;
        height: 4.38rem;
        border: 0.02rem solid #707070;
        margin-bottom: 0.4rem;
        .carousel-img-item {
            width: 100%;
            height: 4.35rem;
        }
    }
    .addon-description-title {
        margin-bottom: 0.2rem;
        @include use-font-normal();
        font-family: var(--font-montserrat-medium);
    }
    .addOn-modal-description {
        @include use-font-small();
        color: $text_3;
        text-align: left;
    }
    .addOn-modal-price-container {
        @include use-font-title();
        font-size: 0.4rem;
        margin-top: 0.4rem;
    }
    .addOn-modal-container {
        display: flex;
        flex-direction: column;
    }
    .footer-container {
        padding: 0 0.2rem 0.4rem;
    }
}
