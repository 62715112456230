@import "@/src/styles/common.scss";
.bottom-share-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .share-link-container {
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 32px;
        height: 22px;
        margin-bottom: 0.2rem;
        .bottom-share-icon {
            font-size: 22px !important;
            color: $text_3;
        }
    }
    .share-image-link {
        padding: 0.14rem;
        .share-image {
            width: auto;
            height: 0.46rem;
            vertical-align: middle;
        }
    }
}
