@import "@/src/styles/common.scss";
.from_wrap {
    background-color: #fff;
    padding: 0.4rem 0.2rem 0.2rem;
    margin-top: 0.2rem;
    display: flex;
    flex-direction: column;

    .code-form-title {
        margin: 0 0 0.2rem;
        text-align: center;
        @include use-font-title();
        span {
            color: $warning;
        }
    }
    .subscrib-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .code-form-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0rem auto 0.1rem;
            width: 100%;
            box-sizing: border-box;
            .code-form-btn.subscribe-btn {
                flex-shrink: 0;
                font-size: 0.32rem !important;
                padding: 0.22rem !important;
                border-radius: 0 0.08rem 0.08rem 0 !important;
                &.code-form-btn-loading {
                    background: #f4f4f4;
                }
                &:hover {
                    font-size: 0.32rem;
                }
            }
        }
        .input-error {
            // padding: 0.15rem;
            color: red;
        }
    }
    .code-form-code {
        width: 10rem;
        height: 100%;
        outline: 0;
        padding: 0;

        .input-root {
            border-radius: 0.08rem 0 0 0.08rem !important;
            border: solid $placeholderColor 0.02rem !important;

            .input-content {
                &.input-error-border {
                    border-color: red !important;
                }
            }
        }
        .input-error {
            background-color: yellow;
        }
    }

    // label
    .subscription-container-formcontrol {
        margin: 0 auto;
        .subscription-container {
          text-align: center;
          margin: 0.4rem auto 0px;
        }
        .checkbox-subscribe-label {
            text-align: center;
            margin: 0.15rem 0;
            align-items: center;
        }
    }
    .image-container {
        text-align: center;
        margin-top: -0.24rem;
        display: flex;
        justify-content: center;
    }
    .image-content {
        height: 0.5rem;
        width: fit-content;
        &.no-link-image {
            margin: 0 auto 0;
        }
    }
    .imgs-cms {
        width: auto !important;
        height: 100%;
    }
}
.success-subscibe-content {
    @include use-font-title();
    text-align: center;
    .content {
        margin-bottom: 0.3rem;
    }
    .link-choose-glasses-now {
        justify-content: center;
    }
}
.range {
    color: $warning;
}

.code-form-code-err {
    border: solid red 0.02rem;
}

.subsctiption-dialog-container {
    .suscription-dialog {
        padding: 0 0.2rem 0.4rem 0.2rem;
        .update-subscription {
            padding: 0.1rem 0.2rem;
            background-color: $main;
            border: 0;
            color: white;
            font-size: 0.26rem;
            font-family: var(--font-montserrat);
        }
        .checkbox-container {
            .label-content {
                align-items: center;
            }
        }
        .checkbox-down-button {
            text-align: center;
            margin-top: 0.4rem;
        }
    }
}
