@import "@/src/styles/common.scss";
.glasses-accessories-list {
    margin-top: 0.4rem;
    .title {
        @include use-font-title();
        font-size: 0.36rem;
        margin-bottom: 0.4rem;
        padding-left: 0.2rem;
    }
    .type-item {
        line-height: 0.44rem !important;
        padding: 0 0.3rem !important;
        border-radius: 0.3rem !important;
        font-family: var(--font-montserrat) !important;
        cursor: pointer;
        flex-shrink: 0;
    }
    .type-item + .type-item {
        margin-left: 0.18rem;
    }
    .items-title {
        margin-left: 0.1rem;
        @include use-font-normal();
    }
    .glasses-accessories-content {
        background: #fff;
        padding: 0.3rem 0 0.3rem 0.2rem;
        .type-list {
            display: flex;
            align-items: center;
            padding-bottom: 0.4rem;
            overflow-x: scroll;
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        }
    }
}
