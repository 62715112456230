@import "@/src/styles/common.scss";
.rating-container {
    display: flex;
    align-items: center;
    .icon-full-star-item {
        font-size: inherit;
    }
    .show-star-numbers {
        margin-left: 0.04rem;
        font-size: 0.28rem;
        line-height: 1.5;
        color: #faaf00;
    }
}
