@import "@/src/styles/common.scss";
.write-review-form {
    .write-review-title.title-margin {
        margin: 0.2rem 0 0;
        font-size: 0.32rem;
        font-family: var(--font-montserrat);
    }
    .review-star-input {
        display: flex;
        align-items: center;
        margin: 0.5rem 0 0.3rem 0;
        .write-review-rating {
            margin-right: 0.16rem;
            font-size: 0.26rem;
        }
        .rating-star {
            font-size: 0.44rem;
        }
    }
    .review-text-input {
    }
    .review-enter-nick {
        margin: 0.45rem 0 0.3rem 0;
        font-size: 0.26rem;
        color: #363636;
    }
    .review-update-nick {
    }
    .review-enter-tips {
        margin: 0.18rem 0 0.55rem 0;
        font-size: 0.26rem;
        color: #666;
    }
    .review-update-btn {
        width: 100%;
        height: 0.7rem;
        border: none;
    }
    .tickets-for-c {
        padding: 0.1rem 0;
        .tickets-label {
            font-size: 0.26rem;
            color: $text;
            font-family: var(--font-montserrat);
            line-height: 1.2;
            letter-spacing: normal;
        }
    }
}
