@import "@/src/styles/common.scss";
.size-canvas-container {
    position: relative;
    padding-bottom: 0;
    canvas.canvas-container {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
    }
    .canvas-inch {
        cursor: pointer;
        color: $main;
    }
}
