@import "@/src/styles/common.scss";
.carousel-container {
    position: relative;
    height: 100%;
    .dot-root {
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        .dot-wrapper {
            .dot-content {
                height: 0.16rem;
                width: 0.16rem;
                background-color: #ccc;
                border-radius: 6px;
                margin: 3px;
                &.active {
                    background-color: #888;
                }
            }
        }
    }
}
