@import "@/src/styles/common.scss";
.customer-show-container {
    .firmoo-show-header {
        justify-content: flex-start !important;
        .customer-show-modal-view-more {
            font-size: 0.28rem;
            color: $main;
            text-decoration: none;
            display: flex;
            align-items: center;
        }
    }
    .customer-show-modal-container {
        padding: 0.38rem 0.3rem;
        box-sizing: border-box;
        color: $text;
        font-size: 0.26rem;
        // 展示图片
        .customer-show-modal-img {
            position: relative;
            .customer-show-modal-img-detail {
                width: 100%;
            }
            .arrow {
                position: absolute;
                top: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 0.6rem;
                height: 0.6rem;
                background: rgba(255, 255, 255, 0.4);
                transform: translateY(-50%);
                &.arrow-right {
                    left: auto;
                    right: 0.2rem;
                }
                &.arrow-left {
                    left: 0.2rem;
                }
            }
        }
        // 评分
        .customer-show-modal-star-container {
            margin-top: 0.46rem;
            display: flex;
            align-items: center;
            .customer-show-modal-star-title {
                margin-right: 0.1rem;
            }
            .review-star {
                font-size: 0.44rem;
            }
            .star-nums {
                margin: 0 0 0 0.1rem;
                font-size: 0.26rem;
            }
        }
        .customer-show-review-txt {
            margin-top: 0.4rem;
            margin-bottom: 0.6rem;
            text-align: left;
            line-height: 0.5rem;
        }
        .customer-show-review-who-time {
            text-align: left;
            color: $text_3;
        }
        .customer-show-go-shop {
            margin-top: 0.45rem;
            width: 100%;
            height: 0.9rem;
            line-height: 0.9rem;
            background: $text;
            color: #fff;
            border: none;
            border-radius: 0;
            list-style: none;
            display: block;
            text-align: center;
        }
    }
}
