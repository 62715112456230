@import "@/src/styles/common.scss";
.reviews-title-container.add-higher-level {
    justify-content: space-between;
    align-items: flex-start;
    .icon-size {
        font-size: 0.44rem;
    }
    .reviews-title {
        @include use-font-title();
    }
}
.write-and-review {
    .write-review-content {
        align-items: center;
        & > .icon-ask-content {
            @include use-icon-normal();
            margin-right: 0.08rem !important;
        }
    }
}

.comment-list-wrapper {
    margin-bottom: 0.4rem;
}

.no-comment {
    padding: 0.2rem 0;
    text-align: center;
    @include use-font-normal();
}

.review-drawer {
    .header {
        .modal-title {
            width: 100%;
            text-align: center;
            font-size: 0.36rem;
            color: $text;
        }
    }
    .review-list-container {
        padding: 0 0.2rem;
    }
}

.bottom-line {
    align-items: center;
    background: #fff;
    box-shadow: 0 0 0.1rem 0.1rem hsla(0, 0%, 5%, 0.05);
    display: flex;
    justify-content: space-between;
    .bottom-container {
        justify-content: space-between;
        width: 100%;
        margin: 0.2rem;
    }
    .button-wrapper {
        justify-content: space-between;
    }
}
