@import "@/src/styles/common.scss";
.select-package-list-container {
    width: 100%;
    margin-bottom: 0.4rem;
    .select-title {
        margin-top: 0.3rem;
        margin-bottom: 0.2rem;
        font-size: 0.28rem;
        font-family: var(--font-montserrat-medium);
        &.shakeX {
            animation: shakeX 0.5s;
            animation-name: shakeX;
        }
    }
    .select-list-flex {
        padding-right: 0.4rem;
        display: flex;
        justify-content: space-between;
    }
    .pack-err-text {
        margin-top: 0.16rem;
        color: red;
        font-size: 0.24rem;
    }
}

@keyframes shakeX {
    from,
    to {
        transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-5px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(5px, 0, 0);
    }
}

.package-detail-contaner {
    width: 340px;
    .am-modal-body {
        padding: 0 10px 10px;
    }
    .package-detail-modal {
        text-align: left;
        .title {
            font-size: 0.28rem;
            color: $text;
            font-family: var(--font-montserrat-bold);
            margin-bottom: 0.2rem;
            text-align: left;
        }
        .price-text {
            padding-left: 0.4rem;
            color: #f69001;
            font-size: 0.24rem;
            font-family: var(--font-montserrat-medium);
            margin-bottom: 0;
            text-align: left;
        }
    }
}
