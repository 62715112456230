@import "@/src/styles/common.scss";
.write-review-container {
    text-align: left;
    color: $text;
    width: 100%;
    padding: 0.5rem 0.15rem;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
    overflow-y: auto;
    margin: 0 !important;

    .product-review-alert-container {
        padding: 0;
        .write-review-border {
            padding-bottom: 0.35rem;
            border-bottom: 0.02rem solid #ccc;
            .write-review-title {
                font-size: 0.32rem;
                font-family: var(--font-montserrat-medium);
                margin: 0;
            }
            .review-star-input {
                display: flex;
                align-items: center;
                .write-review-rating {
                    margin-right: 0.16rem;
                    font-size: 0.26rem;
                }
            }
            .review-text-input {
                width: 100%;
                height: auto;
                box-sizing: border-box;
                &::-webkit-input-placeholder {
                    font-size: 0.28rem !important;
                    color: #d0d0d0;
                }
                &:-moz-placeholder {
                    font-size: 0.26rem;
                    color: #d0d0d0;
                }
                &::-moz-placeholder {
                    font-size: 0.26rem;
                    color: #d0d0d0;
                }
                .input-props-root {
                    font-size: 0.28rem;
                    color: $text;
                }
                .input-form-help-text-error {
                    font-size: 0.28rem;
                    line-height: 1.5;
                    letter-spacing: normal;
                    font-family: var(--font-montserrat);
                    margin: 0.06rem 0.28rem 0;
                }
            }
            .review-update-photo {
                padding-top: 0.35rem;
                font-size: 0.32rem;
                color: $text;
            }
            .title-margin-suggestion {
                margin: 0.3rem 0;
                .label-width {
                    display: inline-block;
                    min-width: 33.33%;
                }
            }
        }
    }
    .review-update-btn {
        width: 100%;
        height: 0.7rem;
        border: none;
        color: #fff;
        background: #000;
    }
}

.iCheckboxInput {
    padding-right: 0.5rem;
    margin: 0.1rem 0;
    input {
        display: none;
    }
    &.radioInput .iCheckboxSpan {
        border-radius: 50%;
    }
    &.radioInput :after {
        border-radius: 50%;
    }
    .iCheckboxSpan {
        display: inline-block;
        height: 0.15rem;
        width: 0.15rem;
        vertical-align: middle;
        margin-bottom: 0.05rem;
        margin-right: 0.1rem;
        border: 0.02rem solid #cbcbcb;
        padding: 0.05rem;
    }
    input:checked + .iCheckboxSpan:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: #363636;
    }
}

.write-review-form-container {
    .header-container {
    }
}
