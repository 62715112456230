@import "@/src/styles/common.scss";
.videoVal {
    position: relative;
    width: 100%;
    height: 100%;
}

.videoPlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(81, 81, 81, 0.5);
    border-radius: 50%;
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:
        padding 0.5s ease-in-out,
        opacity 0.5s ease-in-out 0.2s;
}

.active {
    padding: 40px;
    opacity: 0;
}

.progressBox {
    position: absolute;
    height: 2px;
    background: rgb(166, 171, 175);
    border-radius: 8px;
    overflow: hidden;
    left: 50%;
    bottom: 24px;
    transform: translate(-50%, -50%);
    width: 85vw;
}

.progress {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0%;
    height: 100%;
    background: rgb(46, 167, 224);
}

.monutBox {
    position: absolute;
    right: 0rem;
    bottom: 1.3rem;
    transform: translate(-50%, -50%);
    background-color: rgb(81, 81, 81, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    height: 0.4rem;
    width: 0.4rem;
    padding: 3px;
}

.imgs {
    width: 0.3rem;
    height: 0.3rem;
}

.Bcontainer {
    height: calc(var(--vh, 100vh) * 100 - 1rem - 3rem);
    width: 100%;
    overflow-y: hidden;
    object-fit: contain;
}
