@import "@/src/styles/common.scss";
.top-notice-container {
    width: 100%;
    color: #fff;
    font-size: 0.28rem;
    position: relative;
    text-align: center;
    line-height: 1.5;
    background-size: auto 100% !important;
    .hidden_swiper {
        flex-direction: column;
        .has_countdown_container {
            margin-top: 0.06rem;
            flex-direction: column;
            .has_countdown_content {
                margin: 0.2rem;
            }
        }
    }
    .carousel-container {
        .carousel-item {
            flex-direction: column;
            // height: 1.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            .carousel-ad_content {
                color: #fff;
                font-size: 0.28rem;
            }
        }
    }
    .top-notice-close {
        position: absolute;
        top: 0.1rem;
        right: 0.1rem;
        cursor: pointer;
        font-family: var(--font-montserrat-bold);
        &.close-icon {
            color: #fff;
            font-size: 0.4rem;
            font-weight: 800;
        }
    }
    // common
    .GoShop_btn {
        &.carousel-goshop {
            margin-top: 0.06rem;
        }
        .link {
            margin-top: 0;
            text-decoration: none;
            color: #fff;
            &:hover {
                color: #fff;
                text-decoration: underline;
            }
            &:active:hover {
                text-decoration: none;
            }
        }
    }
}

.top-notice-container-padding{
  padding: 0.1rem 0.5rem 0.1rem 0.1rem;
}