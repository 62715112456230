@import "@/src/styles/common.scss";
.term-container {
    background-color: #fff;
    padding: 0;
    margin-top: 0;
    .advantage-item {
        padding: 0.4rem;
        display: flex;
        .advantage-icon {
            flex: 0 0 1rem;
            display: flex;
            align-items: center;
            .exchange-icon {
                @include use-icon-large();
            }
        }
        .advantage-text {
            color: $text;
            .advantage-title {
                @include use-font-title();
                margin-bottom: 0.1rem;
            }
        }
    }
    .advantage-line {
        width: 96%;
        height: 0.02rem;
        margin: 0 auto;
        background: $gray_4;
        &:last-of-type {
            display: none !important;
        }
    }
}

.cursor {
    cursor: pointer;
}
