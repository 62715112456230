@import "@/src/styles/common.scss";
.text-filed-root {
    width: 100%;
    &::-webkit-input-placeholder {
        @include use-font-normal(#d0d0d0);
    }
    &:-moz-placeholder {
        @include use-font-normal(#d0d0d0);
    }
    &::-moz-placeholder {
        @include use-font-normal(#d0d0d0);
    }
}
.input-label {
    @include use-font-normal();
    margin: 0;
    letter-spacing: normal;
    padding: 0;
    .textarea-input {
        padding: 0.2rem 0.2rem;
    }
    .focused-textarea,
    fieldset {
        border-width: 0.02rem;
    }
}
.input-help-text-error {
    font-size: inherit;
}
