@import "@/src/styles/common.scss";
.review-item-container {
    padding: 0.4rem 0;
    border-bottom: 0.02rem solid $gray_4;
    width: 100%;
    .star-container {
        justify-content: flex-start;
        .icon-size {
            font-size: 0.44rem;
        }
    }
    .review-text {
        margin-top: 0.2rem;
        margin-bottom: 0.1rem;
        @include use-font-normal();
    }
    .review-time {
        font-size: 0.24rem;
        color: $text_3;
    }
    .review-user-img-container {
        width: 2rem;
        overflow: hidden;
        margin-top: 0.2rem;
    }
    .show-more {
        justify-content: flex-start;
        margin-top: 0.2rem;
        .icon-ask,
        .icon-like {
            font-size: 0.44rem;
            color: $main;
            cursor: pointer;
        }
        .icon-like {
            margin-left: 0.4rem;
        }
    }
    .reply-info {
        padding-top: 0.4rem;
        margin-top: 0.4rem;
        border-top: 0.02rem solid $gray_4;
        .reply-info-item {
            justify-content: flex-start;
            .reply-p {
                margin-bottom: 0;
                .customer-name {
                    font-family: var(--font-montserrat-medium);
                    margin-right: 0.2rem;
                }
                .date-added {
                    font-size: 0.24rem;
                    margin-left: 0.2rem;
                }
            }
        }
    }
    .comment-container {
        margin-top: 0.2rem;
    }
}
