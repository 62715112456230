@import "@/src/styles/common.scss";
.category_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.4rem 0;
    .category_item {
        width: 33.33%;
        padding: 0.2rem;
        .category_name {
            line-height: 1.5;
            text-align: center;
            font-size: 0.24rem;
            padding-top: 0.2rem;
        }
    }
}
